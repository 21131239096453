import "core-js/modules/web.dom.iterable";
var _getUserLanguage;
import { setWebsiteDirection, getWebsiteDirection, setUserLanguage } from './userHelpers';
import { CountryCode, EmitterTypes, RESPONSE_STATUS } from '../defines';
import i18next from 'i18next';
import { emitter } from './emitter';
import { BACKUPLNG } from '@app/i18n/locales/backupLng';
import { getUserLanguage } from '@utils/helpers/userHelpers';
import { getCookieValue, setCookieValue } from './storage';
import { getDeliveryTimesWithCharges } from '@app/services/cart';
const translate = i18next.getFixedT((_getUserLanguage = getUserLanguage()) !== null && _getUserLanguage !== void 0 ? _getUserLanguage : 'en', 'translations');
export const capitalize = text => {
    if (text && text.length) {
        return "" + text.charAt(0).toUpperCase() + text.slice(1);
    }
    return '';
};
export const getCountryCode = () => {
    if (typeof window === 'undefined') {
        return CountryCode.Emirates;
    }
    const hostname = window.location.hostname.split('.')[0].toLowerCase();
    if (hostname === 'www' || hostname === 'floranow') {
        return CountryCode.Emirates;
    }
    let countryCode = '';
    const keys = Object.keys(CountryCode);
    for (const key of keys) {
        if (hostname.indexOf(CountryCode[key]) > -1) {
            countryCode = CountryCode[key];
            break;
        }
    }
    return countryCode.length !== 0 ? countryCode : 'ae';
};
export const getCatalogUrl = link => {
    if (!link) {
        return '';
    }
    // TODO:: will be enhanced later
    // commercial/flowers
    return link.split('/')[1];
};
export const numberWithCommas = number => {
    if (!number && number !== 0) {
        return number;
    }
    return parseFloat(number.toString()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatePrice = function (price, quantity) {
    if (quantity === void 0) {
        quantity = 1;
    }
    if (!price) {
        return '99';
    }
    const parsedValue = numberWithCommas((parseFloat(price.toString()) * quantity).toFixed(2));
    return parsedValue === 'NaN' ? 'N/A' : parsedValue;
};
export const isImageAvailable = imageUrl => {
    const http = new XMLHttpRequest();
    http.open('HEAD', imageUrl, false);
    http.send();
    return http.status != 404;
};
export const changeWebsiteDirection = direction => {
    if (direction && direction === 'rtl') {
        setWebsiteDirection('rtl');
    }
    else {
        setWebsiteDirection('ltr');
    }
    emitter.emit(EmitterTypes.DIRECTION_CHANGED);
};
export const changeLanguage = language => {
    if (getUserLanguage() === language) {
        return;
    }
    localStorage.removeItem('pages');
    switch (language) {
        case 'ar':
            setUserLanguage('ar');
            changeWebsiteDirection('rtl');
            i18next.changeLanguage('ar').then(ll => {
                location.reload();
                emitter.emit(EmitterTypes.DIRECTION_CHANGED);
            });
            break;
        default:
            setUserLanguage('en');
            changeWebsiteDirection('ltr');
            i18next.changeLanguage('en').then(ll => {
                location.reload();
                emitter.emit(EmitterTypes.DIRECTION_CHANGED);
            });
            break;
    }
};
export const isRtl = () => {
    if (!i18next.isInitialized) {
        const direction = getWebsiteDirection();
        return Boolean(direction && direction === 'rtl');
    }
    return i18next.dir() === 'rtl';
};
export function checkCategoriesValidCache(now) {
    if (localStorage.getItem('categories-creation-date')) {
        const categoriesCreationDate = parseInt(localStorage.getItem('categories-creation-date'));
        const targetTime = 1000 * 60 * 10;
        const categoriesDifference = (now - categoriesCreationDate) / targetTime;
        if (categoriesDifference > 10) {
            localStorage.setItem('categories-creation-date', now.toString());
            emitter.emit(EmitterTypes.UPDATE_CATEGORIES);
        }
    }
    else {
        localStorage.setItem('categories-creation-date', now.toString());
        emitter.emit(EmitterTypes.UPDATE_CATEGORIES);
    }
}
export function checkPagesValidCache(now) {
    if (localStorage.getItem('pages-creation-date')) {
        const pagesCreationDate = parseInt(localStorage.getItem('pages-creation-date'));
        const targetTime = 1000 * 60 * 1;
        const pagesDifference = (now - pagesCreationDate) / targetTime;
        const timeOutCacheInMuintes = 5;
        if (pagesDifference > timeOutCacheInMuintes) {
            localStorage.setItem('pages-creation-date', now.toString());
            emitter.emit(EmitterTypes.UPDATE_PAGES);
        }
    }
    else {
        localStorage.setItem('pages-creation-date', now.toString());
        emitter.emit(EmitterTypes.UPDATE_PAGES);
    }
}
export const checkForUpdates = () => {
    const now = new Date().getTime();
    checkCategoriesValidCache(now);
    checkPagesValidCache(now);
};
const handleFallBackLanguage = key => {
    const { ar, en } = BACKUPLNG;
    return isRtl() ? ar[key] ? ar[key] : key : en[key] ? en[key] : key;
};
export const t = (key, fallback) => {
    if (!i18next.isInitialized) {
        return handleFallBackLanguage(key);
    }
    try {
        return translate([key, fallback !== null && fallback !== void 0 ? fallback : handleFallBackLanguage(key)]);
    }
    catch (error) {
        return handleFallBackLanguage(key);
    }
};
export function deepClone(obj) {
    if (typeof obj !== 'object') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(function (item) {
            return typeof item === 'object' ? deepClone(item) : item;
        });
    }
    const clone = Object.assign({}, obj);
    Object.keys(clone).forEach(key => clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]);
    return clone;
}
export function isTradeSubdomain() {
    const hostname = window.location.hostname.split('.')[0];
    const tradeSubdomains = ['trade-marketplace-dev', 'trade'];
    return tradeSubdomains.includes(hostname);
}
export function getUrl(url) {
    if (url.includes('http') && url.includes('floranow.com')) {
        return url;
    }
    else {
        const location = window.location;
        const baseUrl = location.protocol + '//' + location.host;
        const firstChar = url.charAt(0);
        if (firstChar !== '/') {
            url = '/'.concat(url);
        }
        return baseUrl + url;
    }
}
export const getPagesInfo = id => {
    var _localStorage$getItem;
    const currentPageArr = JSON.parse((_localStorage$getItem = localStorage.getItem('pages')) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : '[]').filter(page => page.id == id);
    if (currentPageArr.length > 0) {
        const page = currentPageArr[0];
        return page;
    }
    else {
        return {};
    }
};
export const setGtmEventMapperCookie = event_name => {
    var _getCookieValue;
    const eventMapperStr = (_getCookieValue = getCookieValue('gtm_event_mapper')) !== null && _getCookieValue !== void 0 ? _getCookieValue : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    eventMapper[event_name] = true;
    setCookieValue('gtm_event_mapper', JSON.stringify(eventMapper));
};
export const getGtmEventMapperCookie = event_name => {
    var _getCookieValue2;
    const eventMapperStr = (_getCookieValue2 = getCookieValue('gtm_event_mapper')) !== null && _getCookieValue2 !== void 0 ? _getCookieValue2 : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    return eventMapper[event_name];
};
export const clearGtmEventMapperCookie = event_name => {
    var _getCookieValue3;
    const eventMapperStr = (_getCookieValue3 = getCookieValue('gtm_event_mapper')) !== null && _getCookieValue3 !== void 0 ? _getCookieValue3 : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    delete eventMapper[event_name];
    setCookieValue('gtm_event_mapper', JSON.stringify(eventMapper));
};
export const fetchDeliveryInfo = async (setDeliveryInfo, setDeliveryCardLoading, setFailedDeliveryInfo, cartId) => {
    var _response$data, _response$data$data;
    setDeliveryInfo([]);
    setDeliveryCardLoading(true);
    setFailedDeliveryInfo(false);
    try {
        const response = await getDeliveryTimesWithCharges(cartId);
        switch (response === null || response === void 0 ? void 0 : response.status) {
            case RESPONSE_STATUS.OK:
                const delivery_info = response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : (_response$data$data = _response$data.data) === null || _response$data$data === void 0 ? void 0 : _response$data$data.delivery_info;
                setDeliveryInfo(delivery_info);
                break;
            default:
                setFailedDeliveryInfo(true);
                setDeliveryCardLoading(false);
                break;
        }
    }
    catch (error) {
        setFailedDeliveryInfo(true);
        setDeliveryCardLoading(false);
    }
};
