import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { navigate } from 'gatsby';
import { createCart } from '@utils/helpers/cartManagement';
import { CountryCode, ErrorMessages, RESPONSE_STATUS } from '@utils/defines/index';
import { clearUserCookies, getCookieValue, setCookieValue } from './storage';
import { getUserInfo as getUserInfoService } from '@app/services/profile';
import keycloak from './keycloak';
// import mixpanel from 'mixpanel-browser';
export const getUserLanguage = () => {
    let lng = getCookieValue('lng');
    if (!lng || lng == '') {
        lng = 'en';
    }
    return lng;
};
export const setUserLanguage = lng => {
    setCookieValue('lng', lng);
};
export const getWebsiteDirection = () => {
    return getCookieValue('website-direction');
};
export const setWebsiteDirection = direction => {
    setCookieValue('website-direction', direction);
};
export const isUserLogged = () => {
    return keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated;
};
export const evaluateExpiryDate = () => {
    if (localStorage.getItem('expires-in')) {
        const subtractThreeMinutes = parseInt(localStorage.getItem('expires-in'), 10) - 180;
        return moment().add(subtractThreeMinutes, 'seconds').toDate();
    }
};
const getUserInfo = async () => {
    try {
        return new Promise((resolve, reject) => {
            var _getCookieValue;
            if (!(keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated)) {
                return resolve(null);
            }
            const user = (_getCookieValue = getCookieValue('user')) !== null && _getCookieValue !== void 0 ? _getCookieValue : '';
            if (user == '') {
                return getUserInfoService().then(response => {
                    var _response$data, _response$data$data, _response$data2, _response$data2$data;
                    if (!response || response.status != RESPONSE_STATUS.OK)
                        return resolve(null);
                    const user = {
                        ...(response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : (_response$data$data = _response$data.data) === null || _response$data$data === void 0 ? void 0 : _response$data$data.user),
                        ...(response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : (_response$data2$data = _response$data2.data) === null || _response$data2$data === void 0 ? void 0 : _response$data2$data.user_info)
                    };
                    setCookieValue('user', JSON.stringify(user));
                    return resolve(user);
                });
            }
            return resolve(JSON.parse(user));
        });
    }
    catch (error) { }
};
export const storeUserLocally = (accessToken, refreshToken, expiresIn) => {
    if (!accessToken || accessToken === 'undefined' || accessToken == '') {
        return;
    }
    return getUserInfoService().then(response => {
        if (!response)
            return;
        const user = {
            ...response.data.data.user,
            ...response.data.data.user_info
        };
        createCart();
        return user;
    });
};
export const handleRefreshToken = refreshToken => {
    return new Promise((resolve, reject) => {
        const body = {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            client_id: process.env.AUTH_CLIENT_ID,
            client_secret: process.env.AUTH_CLIENT_SECRET
        };
        fetch('/auth/token', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            return res.json();
        }).then(response => {
            if (response.status === 503) {
                return;
            }
            if (response.access_token !== 'undefined' && response.refresh_token !== 'undefined') {
                resolve(response);
                return;
            }
            resolve(ErrorMessages.UNDEFINED_TOKEN);
        }).catch(error => {
            reject(error);
        });
    });
};
//
export const refreshUser = path => {
    return new Promise(async (resolve, reject) => {
        const refresh_token = typeof localStorage !== 'undefined' && localStorage.getItem('refresh-token');
        if (refresh_token) {
            try {
                const response = await handleRefreshToken(refresh_token);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
            window.location.href = path;
        }
    });
};
export const getUserMarket = function (country, isBulk) {
    if (isBulk === void 0) {
        isBulk = false;
    }
    let extension = process.env.EXTENSION_MARKET;
    if (extension === undefined) {
        extension = '-marketplace-dev';
    }
    if (isBulk) {
        return "https://" + CountryCode.TRADE + extension + ".floranow.com";
    }
    switch (country.toLowerCase()) {
        case CountryCode.Emirates:
            return "https://" + CountryCode.Emirates + extension + ".floranow.com";
        case CountryCode.Jordan:
            return "https://" + CountryCode.Jordan + extension + ".floranow.com";
        case CountryCode.Kuwait:
            return "https://" + CountryCode.Kuwait + extension + ".floranow.com";
        case CountryCode.Qatar:
            return "https://" + CountryCode.Qatar + extension + ".floranow.com";
        case CountryCode.KSA:
            return "https://" + CountryCode.KSA + extension + ".floranow.com";
        case CountryCode.TRADE:
            return "https://" + CountryCode.TRADE + extension + ".floranow.com";
        default:
            return "https://" + CountryCode.Emirates + extension + ".floranow.com";
    }
};
export const logoutUser = function (currentPage) {
    if (currentPage === void 0) {
        currentPage = '/';
    }
    return getUserInfo().then(user => {
        if (user) {
            var _tokenParsed$imperson, _userData$country, _keycloak$tokenParsed, _keycloak$tokenParsed2, _keycloak$tokenParsed3;
            const userData = user;
            const tokenParsed = keycloak === null || keycloak === void 0 ? void 0 : keycloak.tokenParsed;
            const id = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.floranow_user_id;
            const username = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.username;
            const email = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.email;
            const impersonator = (_tokenParsed$imperson = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.impersonator) !== null && _tokenParsed$imperson !== void 0 ? _tokenParsed$imperson : false;
            setCookieValue('l_u', JSON.stringify({
                id: id,
                username: username,
                email: email,
                country: (_userData$country = userData.country) !== null && _userData$country !== void 0 ? _userData$country : 'undefined',
                impersonator: impersonator,
                roles: keycloak === null || keycloak === void 0 ? void 0 : (_keycloak$tokenParsed = keycloak.tokenParsed) === null || _keycloak$tokenParsed === void 0 ? void 0 : (_keycloak$tokenParsed2 = _keycloak$tokenParsed.resource_access) === null || _keycloak$tokenParsed2 === void 0 ? void 0 : (_keycloak$tokenParsed3 = _keycloak$tokenParsed2.marketplace) === null || _keycloak$tokenParsed3 === void 0 ? void 0 : _keycloak$tokenParsed3.roles
            }));
            const userMarket = getUserMarket(userData.country, userData.user_type == 'bulk');
            const lng = getUserLanguage() || 'en';
            const websiteDirection = getWebsiteDirection() || 'ltr';
            localStorage.clear();
            clearUserCookies();
            setUserLanguage(lng);
            setWebsiteDirection(websiteDirection);
            // used href to cause a refresh after the user logs out & logout from root site
            let backTo = currentPage;
            if (backTo === '/') {
                backTo = userMarket;
            }
            // mixpanel.track('Sign Out');
            // mixpanel.reset();
            // window.location.href = `${userMarket}/connect.html?logout=true&backTo=${encodeURI(
            //   backTo
            // )}`;
        }
    });
};
export const needsResetInfo = () => {
    const accessToken = keycloak === null || keycloak === void 0 ? void 0 : keycloak.token;
    if (accessToken && accessToken !== 'undefined') {
        const { user } = jwtDecode(accessToken);
        if (user && user.need_reset_info) {
            return true;
        }
    }
    return false;
};
export const linkTo = function (path, skipValidation) {
    if (skipValidation === void 0) {
        skipValidation = false;
    }
    return needsResetInfo() && !skipValidation ? '/dashboard/profile' : path;
};
export const userIsMaster = () => {
    return new Promise((resolve, reject) => {
        var _ref;
        if (!(keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated)) {
            return resolve(false);
        }
        const isMaster = getCookieValue('is_master');
        if (isMaster == '') {
            getUserInfo().then(user => {
                var _user$is_master, _user$is_master2;
                setCookieValue('is_master', (_user$is_master = user === null || user === void 0 ? void 0 : user.is_master) !== null && _user$is_master !== void 0 ? _user$is_master : false);
                resolve((_user$is_master2 = user === null || user === void 0 ? void 0 : user.is_master) !== null && _user$is_master2 !== void 0 ? _user$is_master2 : false);
            });
        }
        return resolve((_ref = isMaster && JSON.parse(isMaster)) !== null && _ref !== void 0 ? _ref : false);
    });
};
export const goTo = function (path, skipValidation, props, isMaster) {
    if (path === void 0) {
        path = '/';
    }
    if (skipValidation === void 0) {
        skipValidation = false;
    }
    if (props === void 0) {
        props = {};
    }
    if (isMaster === void 0) {
        isMaster = false;
    }
    if (needsResetInfo() && !skipValidation) {
        navigate('/dashboard/profile');
        return;
    }
    if (isMaster && path === '/cart') {
        navigate('/account/all/cart');
        return;
    }
    navigate(path, {
        state: props
    });
};
export const goToStore = country => {
    if (needsResetInfo()) {
        navigate('/dashboard/profile');
        return;
    }
    const rootMarket = process.env.ROOT_MARKET;
    const alternativeMarket = process.env.ALTER_MARKET;
    let extra = '';
    if (window.location.origin !== rootMarket && window.location.origin !== alternativeMarket) {
        extra = '?send=true';
    }
    const userMarket = getUserMarket(country);
    const target = userMarket + "/connect.html" + extra;
    window.location.replace(target);
};
export const checkNeedsResetInfo = () => {
    if (needsResetInfo()) {
        goTo('/dashboard/profile');
    }
};
export const getUser = () => {
    return getUserInfo().then(user => {
        return user;
    });
};
export const getCurrency = () => {
    if (typeof localStorage !== 'undefined') {
        if (!localStorage.getItem('cart')) {
            return 'AED';
        }
        const cart = JSON.parse(localStorage.getItem('cart'));
        if (Array.isArray(cart)) {
            return cart[0].data.attributes.currency;
        }
        else {
            return cart.attributes.currency;
        }
    }
    return 'AED';
};
export const getCartTotal = () => {
    if (typeof localStorage !== 'undefined' && (keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated)) {
        return getUser().then(user => {
            if (!user) {
                return null;
            }
            const cartInStorage = localStorage.getItem('cart');
            if (!cartInStorage) {
                return null;
            }
            const cart = JSON.parse(cartInStorage);
            return cart.attributes ? cart.attributes.item_total : null;
        });
    }
    return null;
};
export const isAdmin = () => {
    if (!(keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated))
        return false;
    return getUser().then(user => {
        if (!user || !user.roles || user.roles.indexOf('admin') === -1) {
            return false;
        }
        return true;
    });
};
export const getUserCurrency = t => {
    if (!(keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated))
        return 'AED';
    return getUserInfo().then(user => {
        if (user) {
            const currencyCode = user.currencyCode;
            return t("currency." + currencyCode, currencyCode);
        }
        else {
            return 'AED';
        }
    });
};
