import axios from 'axios';
export const changePassword = async (currentPassword, confirmPassword, newPassword) => {
    return axios.post('/api/v1/profile', {
        old_password: currentPassword,
        confirmed_password: confirmPassword,
        password: newPassword
    }).then(response => {
        return response;
    });
};
export const getUserInfo = async () => {
    return axios.get('/api/v1/profile').then(response => {
        return response;
    });
};
export const updateProfile = async (data) => {
    return axios.post('/api/v1/profile', {
        ...data
    }).then(response => {
        return response;
    });
};
export const resetPassword = async (data) => {
    return axios.post('/api/v1/reset-password', {
        ...data
    }).then(response => {
        return response;
    });
};
export const changePasswordAfterReset = async (data) => {
    return axios.put('/api/v1/reset-password', {
        ...data
    }).then(response => {
        return response;
    });
};
