export let CountryCode;
(function (CountryCode) {
    CountryCode["WWW"] = "ae";
    CountryCode["Jordan"] = "jo";
    CountryCode["Emirates"] = "ae";
    CountryCode["Qatar"] = "qa";
    CountryCode["Kuwait"] = "kw";
    CountryCode["KSA"] = "sa";
    CountryCode["TRADE"] = "trade";
})(CountryCode || (CountryCode = {}));
export let PageType;
(function (PageType) {
    PageType["Featuring"] = "Featuring";
    PageType["Listing"] = "Listing";
})(PageType || (PageType = {}));
export let CatalogLayout;
(function (CatalogLayout) {
    CatalogLayout["GRID"] = "GRID";
    CatalogLayout["LINE"] = "LINE";
})(CatalogLayout || (CatalogLayout = {}));
export const AvailableCategoryImage = ['accessories', 'deals', 'flowers', 'greeneries', 'orchids', 'plants', 'roses'];
export let StatusColor;
(function (StatusColor) {
    StatusColor["WARNING"] = "#fef5ec";
    StatusColor["NORMAL"] = "#fff";
    StatusColor["ALERT"] = "#EF4317";
})(StatusColor || (StatusColor = {}));
export let SizeModes;
(function (SizeModes) {
    SizeModes["QUARTER"] = "calc(64.7rem /2)";
    SizeModes["HALF"] = "64.7rem";
    SizeModes["FULL"] = "132.7rem";
})(SizeModes || (SizeModes = {}));
export let FilterItemsTypes;
(function (FilterItemsTypes) {
    FilterItemsTypes["CHECKBOX"] = "checkbox";
    FilterItemsTypes["CALENDAR"] = "calendar";
    FilterItemsTypes["COLORPICKER"] = "color-picker";
})(FilterItemsTypes || (FilterItemsTypes = {}));
export let ProductProperties;
(function (ProductProperties) {
    ProductProperties["MainColour"] = "B01";
    ProductProperties["MinimumStemLength"] = "S20";
    ProductProperties["Origin"] = "S62";
})(ProductProperties || (ProductProperties = {}));
export let EmitterTypes;
(function (EmitterTypes) {
    EmitterTypes["USER_LOGGEDIN"] = "user_logged_in";
    EmitterTypes["ADDED_TO_CART"] = "added_to_cart";
    EmitterTypes["UPDATE_PRODUCT_COUNT"] = "update_product_count";
    EmitterTypes["USER_SESSION_TIMEOUT"] = "user_session_timeout";
    EmitterTypes["USER_BLOCKED"] = "user_blocked";
    EmitterTypes["DIRECTION_CHANGED"] = "direction_changed";
    EmitterTypes["UPDATE_CATEGORIES"] = "update_categories";
    EmitterTypes["UPDATE_PAGES"] = "update_pages";
})(EmitterTypes || (EmitterTypes = {}));
export let GroupBy;
(function (GroupBy) {
    GroupBy["CATEGORY"] = "Category";
    GroupBy["DELIVERY_DATE"] = "Delivery Date";
    GroupBy["SUPPLIER"] = "Supplier";
})(GroupBy || (GroupBy = {}));
export let CartItemOptions;
(function (CartItemOptions) {
    CartItemOptions["DELETE_CART_ITEM"] = "delete_cart_item";
    CartItemOptions["ADD_ITEM_TO_LIST"] = "add_item_to_list";
})(CartItemOptions || (CartItemOptions = {}));
export let SortOptions;
(function (SortOptions) {
    SortOptions["CLEAR_FILTERS"] = "Clear filters";
    SortOptions["COLOR"] = "color";
    SortOptions["ORIGIN"] = "origin";
    SortOptions["PRICE"] = "price";
    SortOptions["NAME"] = "name";
})(SortOptions || (SortOptions = {}));
export let PriceUnitName;
(function (PriceUnitName) {
    PriceUnitName["PIECE"] = "piece";
    PriceUnitName["BUNCH"] = "bunch";
    PriceUnitName["BOX"] = "box";
    PriceUnitName["LAYER"] = "layer";
    PriceUnitName["TROLLY"] = "trolly";
})(PriceUnitName || (PriceUnitName = {}));
export let ErrorMessages;
(function (ErrorMessages) {
    ErrorMessages["REFRESH_TOKEN_EXPIRED"] = "The provided authorization grant is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.";
    ErrorMessages["UNDEFINED_TOKEN"] = "undefined token";
})(ErrorMessages || (ErrorMessages = {}));
export let RatingNames;
(function (RatingNames) {
    RatingNames["GENERAL"] = "general";
    RatingNames["QUALITY"] = "quality";
    RatingNames["FRESHNESS"] = "freshness";
    RatingNames["DELIVERY"] = "delivery";
    RatingNames["EXACT_SPECS"] = "exactSpecs";
})(RatingNames || (RatingNames = {}));
export let ProfileTypes;
(function (ProfileTypes) {
    ProfileTypes["ORDERS"] = "orders";
    ProfileTypes["INVOICES"] = "invoices";
    ProfileTypes["PROFILE"] = "my profile";
    ProfileTypes["CHANGE_PASSWORD"] = "change password";
    ProfileTypes["CURRENT_ORDERS"] = "current orders";
    ProfileTypes["STANDING_ORDERS"] = "standing orders";
})(ProfileTypes || (ProfileTypes = {}));
export let InvoiceActionTypes;
(function (InvoiceActionTypes) {
    InvoiceActionTypes["PENDING"] = "pending";
    InvoiceActionTypes["CLOSED"] = "closed";
    InvoiceActionTypes["ALL_INVOICES"] = "all invoices";
})(InvoiceActionTypes || (InvoiceActionTypes = {}));
export const dashboardMenuTree = [{
        title: 'Finance',
        items: [{
                title: 'Invoices',
                icon: 'invoices_icon.svg',
                path: '/dashboard/invoices'
            }]
    }, {
        title: 'Orders',
        items: [{
                title: 'Orders',
                icon: 'orders_icon.svg',
                path: '/dashboard/orders'
            }, {
                title: 'Current Orders',
                icon: 'pending_icon.svg',
                path: '/dashboard/current-orders'
            }]
    }, {
        title: 'Account info & security',
        items: [{
                title: 'My profile',
                icon: 'account_info_icon.svg',
                path: '/dashboard/profile'
            }, {
                title: 'Change password',
                icon: 'change_password_icon.svg',
                path: '/dashboard/change-password'
            }]
    }];
export let BreadCrumbRoots;
(function (BreadCrumbRoots) {
    BreadCrumbRoots["COMMERCIAL"] = "Commercial -> ";
    BreadCrumbRoots["FLORICODE"] = "Floricode -> ";
})(BreadCrumbRoots || (BreadCrumbRoots = {}));
export let GeneralStrings;
(function (GeneralStrings) {
    GeneralStrings["SOLD_OUT"] = "Sold out";
    GeneralStrings["OUT_OF_STOCK"] = "Out of stock";
    GeneralStrings["EXPIRED"] = "Expired";
})(GeneralStrings || (GeneralStrings = {}));
export let RESPONSE_STATUS;
(function (RESPONSE_STATUS) {
    RESPONSE_STATUS[RESPONSE_STATUS["OK"] = 200] = "OK";
    RESPONSE_STATUS[RESPONSE_STATUS["CREDINTALS_WRONG"] = 400] = "CREDINTALS_WRONG";
    RESPONSE_STATUS[RESPONSE_STATUS["NOT_AUTHORISED"] = 401] = "NOT_AUTHORISED";
    RESPONSE_STATUS[RESPONSE_STATUS["BLOCKED"] = 422] = "BLOCKED";
    RESPONSE_STATUS[RESPONSE_STATUS["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
    RESPONSE_STATUS[RESPONSE_STATUS["EmptyInvoices"] = -200] = "EmptyInvoices";
    RESPONSE_STATUS[RESPONSE_STATUS["EmptyOrders"] = -200] = "EmptyOrders";
    RESPONSE_STATUS[RESPONSE_STATUS["OK201"] = 201] = "OK201";
    RESPONSE_STATUS[RESPONSE_STATUS["NO_FLORI_ACCOUNT"] = 500] = "NO_FLORI_ACCOUNT";
    RESPONSE_STATUS[RESPONSE_STATUS["MAINTENANCE"] = 503] = "MAINTENANCE";
    RESPONSE_STATUS[RESPONSE_STATUS["SERVER_DOWN"] = 504] = "SERVER_DOWN";
})(RESPONSE_STATUS || (RESPONSE_STATUS = {}));
export let GTM_EVENT;
(function (GTM_EVENT) {
    GTM_EVENT["PURCHASE"] = "purchase";
    GTM_EVENT["LOGIN"] = "login";
    GTM_EVENT["USER_DATA"] = "userData";
    GTM_EVENT["VIEW_ITEM"] = "view_item";
    GTM_EVENT["VIEW_ITEM_LIST"] = "view_item_list";
    GTM_EVENT["ADD_TO_CART"] = "add_to_cart";
    GTM_EVENT["REMOVE_FROM_CART"] = "remove_from_cart";
    GTM_EVENT["SELECT_ITEM"] = "select_item";
    GTM_EVENT["VIEW_CART"] = "view_cart";
    GTM_EVENT["VIEW_PROMOTION"] = "view_promotion";
    GTM_EVENT["SELECT_PROMOTION"] = "select_promotion";
})(GTM_EVENT || (GTM_EVENT = {}));
export let URL_TYPE;
(function (URL_TYPE) {
    URL_TYPE["RESET_PAGE"] = "reset_page";
    URL_TYPE["KEEP_PAGE"] = "keep_page";
})(URL_TYPE || (URL_TYPE = {}));
