import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import counter from '../slices/counter';
import isScrolling from '../slices/isScrolling';
const reducer = combineReducers({
    counter,
    isScrolling
});
const store = configureStore({
    reducer
});
export default store;
