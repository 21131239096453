import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isScrolling: false
};
const isScrolling = createSlice({
    name: 'SetIsScrolling',
    initialState,
    reducers: {
        SetIsScrolling: (state, _ref) => {
            let { payload } = _ref;
            return {
                ...state,
                ...payload
            };
        }
    }
});
export const { SetIsScrolling } = isScrolling.actions;
export default isScrolling.reducer;
