import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    counter: 0
};
const counter = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        INCREMENT: state => {
            return {
                counter: state.counter + 1
            };
        },
        DECREMENT: state => {
            return {
                counter: state.counter - 1
            };
        }
    }
});
export const { INCREMENT, DECREMENT } = counter.actions;
export default counter.reducer;
