export const BACKUPLNG = {
  ar: {
    'login.username': 'اسم المستخدم',
    'login.password': 'كلمة المرور',
    'login.login': ' تسجيل الدخول',
    'login.register': 'انشاء حساب',
    'login.forgot_password': 'نسيت كلمة المرور',
    'login.remember_me': 'تذكر حسابي',
    'login.contact_us': 'تواصل معنا',
    'login.name': 'الاسم',
    'login.confirm_password': 'تأكيد كلمة المرور',
    'login.email': 'البريد الإلكتروني',
    'login.phone_number': 'رقم الهاتف',
    'login.business_type': 'نوع العمل',
    'login.company_name': 'اسم الشركة',
    'login.country': 'الدولة',
    'login.back_to_login': 'العودة لتسجيل الدخول',
    'login.type_password_again': 'اكتب كلمة مرورك مرة أخرى',
    'login.language': 'اللغة',
    'login.new_customer': ' مستخدم جديد ؟',
    'login.flower_shops': 'محل ورد',
    'login.weddings_and_events': 'اعراس واحتفالات',
    'login.hotels': 'فنادق',
    'login.supermarket': 'بقالة',
    'login.fully_serviced_client': 'عميل مخدوم بالكامل',
    'login.your_full_name': 'اسمك الكامل',
    'login.your_password': 'كلمة السر الخاصة بك',
    'general.enter': 'أدخل',
    'footer.about_us': 'عن الشركة',
    'footer.terms_and_conditions': 'الشروط والأحكام',
    'footer.privacy_policy': 'سياسة الخصوصية',
    'footer.always_here_to_help': 'نحن بخدمتكم',
    'footer.reach_out_to_us': 'تواصل معنا عبر أي من قنوات الدعم الإلكترونية',
    'footer.email': 'البريد الإلكتروني',
    'footer.phone_number': 'رقم الهاتف',
    'footer.whatsapp': 'واتس آب',
    'footer.our_story': 'قصتنا',
    'footer.our_vision': 'رؤيتنا',
    'footer.our_mission': 'مهمتنا',
    'footer.updates': 'آخر التحديثات',
    'footer.news': 'أخبار',
    'footer.blogs': 'المدونات',
    'footer.tips_and_tricks': 'نصائح ',
    'footer.contact_us': 'تواصل معنا',
    'footer.send_us_a_message': 'ارسل لنا رسالة ',
    'footer.stay_up_to_date':
      'ابق على اطلاع بأحدث العروض والنصائح والأخبار والأحداث',
    'footer.subscribe': 'اشترك معنا',
    'footer.write_your_email': 'ادخل بريدك الإلكتروني',
    'header.login': 'تسجيل الدخول',
    'header.register': 'تسجيل',
    'header.account_settings': 'اعدادات الحساب',
    'header.balance': 'رصيد حساب',
    'header.cart': 'عربة التسوق',
    'header.search_input_placeholder': 'قم بكتابة ما تبحث عنه',
    'header.continue_shopping': 'مواصلة التسوق',
    'header.fetching_items': 'يتم تحميل العناصر',
    'listing.results_for': 'نتائج لـ',
    'listing.total_results': 'إجمالي النتائج',
    'listing.sort_by': 'صنف حسب',
    'listing.reset': 'مسح',
    'listing.price': 'السعر',
    'listing.colour': 'اللون',
    'listing.name': 'الاسم',
    'listing.share_result': 'مشاركة النتائج',
    'listing.ascending': 'تصاعدي',
    'listing.descending': 'تنازلي',
    'listing.order_by': 'صنف',
    'listing.empty_page': 'سيتم توفر العروض قريباً',
    'side_filter.delivery_date': 'تاريخ التسليم',
    'side_filter.search': 'البحث',
    'side_filter.categories': 'التصنيفات',
    'side_filter.suppliers': 'الموردون',
    'side_filter.origin_suppliers': 'البائعين',
    'side_filter.colours': ' الألوان',
    'side_filter.length_range': 'نطاق الطول',
    'side_filter.price_range': 'نطاق السعر',
    'side_filter.origin': 'الأصل',
    'side_filter.search_within_products': 'ابحث ضمن المنتجات',
    'side_filter.colour': 'اللون',
    'side_filter.apply': 'تطبيق',
    'side_filter.to': 'إلى',
    'listing_top_filter.remove_filter': 'إزالة الفلتر',
    'listing_top_filter.delivery_date': 'تاريخ التسليم',
    'listing_top_filter.product_name': 'اسم المنتج',
    'listing_top_filter.colour': 'اللون',
    'listing_top_filter.max_length_range': 'الحد  الأقصى للطول',
    'listing_top_filter.supplier': 'الموزع',
    'listing_top_filter.origin_supplier': 'البائع',
    'listing_top_filter.category': 'الصنف',
    'listing_top_filter.min_length_range': 'الحد الأدنى للطول',
    'listing_top_filter.origin': 'الأصل',
    'listing_top_filter.price_start': 'السعر البدائي',
    'listing_top_filter.price_end': 'السعر النهائي',
    'product_item.grower': 'مزارع',
    'product_item.min_order_unit': 'الحد الأدنى لوحدة الطلب',
    'product_item.supplier': 'المورد',
    'product_item.seller': 'البائع',
    'product_item.origin_supplier': 'المورد',
    'product_item.earliest_delivery_date': 'أقرب تاريخ تسليم',
    'product_item.total_price': 'اجمالي السعر',
    'product_item.add_to_cart': 'أضف إلى السلة',
    'product_item.in_stock': 'الكمية المتوفرة',
    'product_item.see_all': 'عرض جميع المنتجات',
    'product_item.prev_offer': 'العرض السابق',
    'product_item.next_offer': 'العرض التالي',
    'product_item.qty_in_stock': 'الكمية في المخزن',
    'product_item.min_order_qty': 'أقل كمية يمكن طلبها',
    'account_popover.login': 'تسجيل الدخول',
    'account_popover.register': 'إنشاء حساب',
    'account_popover.my_account': 'حسابي',
    'account_popover.my_profile': 'ملف الشخصي',
    'account_popover.logout': 'تسجيل الخروج ',
    'account_popover.orders': 'الطلبيات',
    'account_popover.standing_orders': 'الطلبيات الدائمة',
    'account_popover.my_orders': 'طلبياتي',
    'account_popover.my_current_orders': 'الطلبيات الحالية',
    'account_popover.my_invoices': 'فواتيري',
    'account_popover.finance': 'ألامور المالية',
    'profile_sidebar.invoices': 'فواتير',
    'profile_sidebar.orders': 'طلبيات',
    'profile_sidebar.finance': 'الأمور المالية',
    'profile_sidebar.current_orders': 'الطلبيات الحالية',
    'profile_sidebar.account_info_and_security': 'معلومات الحساب والأمن',
    'profile_sidebar.my_profile': 'حسابي',
    'profile_sidebar.change_password': 'تغيير كلمة المرور',
    'profile_sidebar.standing_orders': 'الطلبيات الدائمة',
    'my_profile.general_info': 'معلومات عامة',
    'my_profile.name': 'الإسم',
    'my_profile.email': 'البريد الإلكتروني',
    'my_profile.phone_number': 'رقم الهاتف',
    'my_profile.company_name': 'اسم الشركة',
    'my_profile.country': 'الدولة',
    'my_profile.current_password': 'كلمة السر الحالية',
    'my_profile.new_password': 'كلمة السر الجديدة',
    'my_profile.confirm_new_password': 'تأكيد كلمة السر الجديدة',
    'my_profile.save_changes': 'حفظ التغييرات',
    'my_profile.need_reset_info': 'عليك بإعادة تعيين معلوماتك قبل المتابعة',
    'my_profile.in_order_to_see_subaccount':
      '* من أجل رؤية معلومات حسابك الفرعي يرجى تسجيل الدخول من الحساب الفرعي.',
    'my_profile.account_id': 'رقم الحساب',
    'my_profile.no_sub_accounts_available': 'لا توجد حسابات فرعية متاحة',
    'cart.your_shopping_cart': 'سلة التسوق الخاصة بك',
    'cart.review_cart_items': 'مراجعة عناصر سلة التسوق',
    'cart.confirm_delivery_date': 'تأكيد تاريخ التسليم',
    'cart.review_order_total': 'مراجعة اجمالي الطلب',
    'cart.track_order': 'تتبع الطلب',
    'cart.delivery_date': 'تاريخ التسليم',
    'cart.review_cart_items_smaller': 'عناصر السلة',
    'cart.confirm_delivery_date_smaller': 'تاريخ التوصيل',
    'cart.review_order_total_smaller': 'تاكيد الطلب',
    'cart.track_order_smaller': 'تتبع الطلب',
    'cart.delivery_date_smaller': 'التوصيل',
    'cart.date': 'التاريخ',
    'cart.delivery_time': 'الوقت',
    'cart.delivery': 'التوصيل',
    'cart.doc_fees': 'رسوم الوثائق',
    'cart.doc_fees_details': 'رسوم وثائق الشحن',
    'cart.for_orders_from': 'لطلبات من ',
    'cart.with_more_than': '  مع أكثر من ',
    'cart.includes_more_than': ' سيقان، تكون رسوم الوثائق مجانية؛ وإلا، ',
    'cart.will_be_applied': ' تطبق رسوم.',
    'cart.no_doc_fees_available': 'لا توجد رسوم وثائق.    ',
    'cart.delivery_windows': 'نوافذ التسليم',
    'cart.unavailable': 'غير متوفرة',
    'cart.order_total_amount': 'المبلغ الإجمالي للطلب',
    'cart.delivery_charges': 'رسوم التوصيل',
    'cart.unavailable_delivery':
      'التسليم غير متوفر، يمكنك المتابعة مع طلب الخروج',
    'cart.time_slots_hint': 'فترات زمنية متاحة فقط للتعبير',
    'cart.self_pickup_hint': 'سيتم تعيين طلبك للاستلام الذاتي في متجرنا',
    'cart.hint': 'تَلمِيح',
    'cart.charges': 'الرسوم',
    'cart.total_delivery_charges': 'اجمالي رسوم التوصيل',
    'cart.time_slots': 'الأوقات',
    'cart.total': 'المجموع',
    'cart.no_delivery_charges_for_orders':
      'لا توجد رسوم توصيل للطلبات التي تزيد عن',
    'cart.add_more_orders_to_get_free_delivery':
      'أضف المزيد من الطلبات للحصول على توصيل مجاني',
    'cart.hint_message':
      'تم احتساب رسوم التوصيل الخاصة بك بالفعل من سلة التسوق السابقة',
    'cart.sub_total_amount': 'المبلغ الاجمالي',
    'cart.vat_rate': 'قيمة الضريبة',
    'cart.confirmed_selected_orders': 'تأكيد الطلبات المختارة',
    'cart.confirm_order': 'أكد الطلب',
    'cart.continue_shopping': 'مواصلة التسوق',
    'cart.group_by': 'اجمع حسب',
    'cart.cart_items': 'عناصر سلة التسوق',
    'cart.item_description': 'وصف السلعة',
    'cart.colour': 'اللون',
    'cart.price_unit': 'السعر/الوحدة',
    'cart.qty': 'الكمية',
    'cart.category': 'صنف',
    'cart.supplier': 'المورد',
    'cart.order_total': 'اجمالي الطلب',
    'cart.show_orders_placed_in': 'عرض الطلبات المقدمة ب',
    'cart.last_three_days': 'آخر ثلاثة أيام',
    'cart.last_three_weeks': 'آخر ثلاثة أسابيع',
    'cart.last_thirty_days': 'آخر ثلاثين يوم',
    'cart.last_three_months': 'آخر ثلاثة أشهر',
    'cart.last_six_months': 'آخر ستة أشهر',
    'cart.date_range': 'نطاق الموعد',
    'cart.your_shopping_cart_is_empty': 'سلة التسوق فارغة',
    'cart.dont_miss_todays_offer': 'لا تفوت عروض اليوم الإستثنائية',
    'cart.if_you_are_already_logged_in':
      'إذا كان لديك حساب ، قم بتسجيل الدخول لرؤية سلة التسوق الخاصة بك.',
    'cart.login': 'تسجيل الدخول',
    'cart.continue': 'استمرار',
    'cart.thank_you_for_shopping': 'شكرا للتسوق مع فلوراناو',
    'cart.check_current_orders': 'تحقق من الطلبيات الحالية',
    'cart.list_my_orders': 'قائمة طلبياتي',
    'cart.order_summary': 'ملخص الطلبية',
    'cart.delete': 'حذف',
    'cart.price_type': 'نوع السعر',
    'cart.cif': 'CIF',
    'cart.fob': 'FOB',
    'cart.additional_cost': 'تكلفة إضافية',
    'cart.are_you_sure_you_want_to_delete':
      'هل أنت متأكد أنك تريد حذف العناصر المحددة؟',
    'cart.cancel': 'الغاء',
    'cart.payment_terms': 'شروط الدفع',
    'cart.remove_all_unavailable_items': 'حذف الكل',
    'cart.unavailable_items': 'العناصر غير المتوفرة',
    'cart.available_items': 'العناصر المتوفرة',
    'cart.cart_icon': 'عربة التسوق',
    'cart.back_to_order_list': 'رجوع إلى قائمة الطلبات',
    'cart.checkout_order ': 'ترتيب الخروج',
    'cart.po_number': 'رقم طلبية الشراء',
    'cart.enter_optional_po_number': 'أدخل رقم طلبية الشراء الاختياري',
    'cart.po_alphanumeric_validation_error': 'أحرف وأرقام فقط!',
    'invoices.invoices': 'فواتير',
    'invoices.filter': 'الفلتر',
    'invoices.apply_filter': 'طبق الفلتر',
    'invoices.all_invoices': 'جميع الفواتير',
    'invoices.closed': 'مدفوعة',
    'invoices.pending': 'غير مدفوعة',
    'invoices.last_three_days': 'اخر ثلاثة ايام',
    'invoices.last_three_weeks': 'اخر ثلاثة اسابيع',
    'invoices.last_thirty_days': 'اخر ثلاثين يوم',
    'invoices.last_three_months': 'اخر ثلاثة اشهر',
    'invoices.last_six_months': 'اخر ستة اشهر',
    'invoices.date_range': 'نطاق الموعد',
    'invoices.pay_selected': 'دفع الفواتير المحددة',
    'invoices.invoice_number': '#الفاتورة',
    'invoices.order_number': '#الطلبية',
    'invoices.status': 'الحالة',
    'invoices.issue_date': 'الإصدار',
    'invoices.invoice_total': 'الإجمالي',
    'invoices.paid_amount': 'المدفوع',
    'invoices.no_invoices_available': 'لا يوجد فواتير',
    'invoices.show_orders_placed_in': 'عرض الطلبات المقدمة ب',
    'invoices.paid': 'مدفوع',
    'invoices.not_paid': 'غير مدفوع',
    'invoices.print': 'طباعة',
    'invoices.pay': 'دفع',
    'invoices.description': 'الوصف',
    'invoices.amount': 'الكمية',
    'invoices.total_amount': 'اجمالي الكمية',
    'invoices.date': 'التاريخ',
    'invoices.back': 'رجوع',
    'invoices.placed_in': 'تم ب',
    'invoices.invoice': 'فاتورة',
    'invoices.credit_note_amount': 'مبلغ مذكرة الائتمان',
    'invoices.type': 'النوع',
    'invoices.showing': 'اظهار',
    'invoices.page': 'صفحة',
    'invoices.list': 'جدول',
    'invoices.list_all': 'جميع',
    'invoices.list_credit_note': 'إشعار دائن',
    'invoices.list_invoices': 'فواتير',
    'invoices.actions': 'أفعال',
    'orders.orders': 'طلبيات',
    'orders.no_orders_available': 'لا يوجد طلبيات للتاريخ المحدد',
    'orders.image': 'الصورة',
    'orders.name': 'الاسم',
    'orders.price': 'السعر',
    'orders.confirmed_amount': 'الكمية المؤكدة',
    'orders.confirmed': 'المؤكدة',
    'orders.total_amount': 'اجمالي الكمية',
    'orders.total': 'الإجمالي',
    'orders.creation_date': 'تاريخ الإنشاء',
    'orders.creation': 'الإنشاء',
    'orders.estimated_delivery_date': 'تاريخ التسليم المتوقع',
    'orders.delivery': 'التسليم',
    'orders.order_number': 'رقم الطلبية',
    'orders.number': 'الرقم',
    'orders.order_type': 'نوع الطلبية',
    'orders.type': 'النوع',
    'standing_orders.standing_orders': 'الطلبيات الدائمة',
    'standing_orders.order_requests': 'الطلبات',
    'standing_orders.no_orders_available': 'لا يوجد طلبيات دائمة',
    'standing_orders.no_order_request_available': 'لا يوجد طلبيات ',
    'standing_orders.image': 'الصورة',
    'standing_orders.name': 'الاسم',
    'standing_orders.delivery_day': 'يوم التوصيل',
    'standing_orders.delivery_date': 'تاريخ التوصيل',
    'standing_orders.requested_qty': 'المطلوبة الكمية',
    'standing_orders.confirmed_qty': 'الكمية المؤكدة',
    'standing_orders.status': 'حالة',
    'standing_orders.approved_quantity': 'الكمية المعتمدة',
    'standing_orders.supplier': 'المورد',
    'standing_orders.quantity': 'كمية',
    'standing_orders.unit_price': 'سعر الوحدة',
    'standing_orders.currency': 'عملة',
    'standing_orders.confirmed': 'تأكيد المورد',
    'standing_orders.reference': 'مرجع',
    'standing_orders.price': 'السعر',
    'standing_orders.orders': 'الطلبات',

    'current_orders.reference': 'مرجع',
    'current_orders.item_description': 'وصف السلعة',
    'current_orders.supplier': 'المورد',
    'current_orders.order_type': 'النوع',
    'current_orders.colour': 'اللون',
    'current_orders.po_number': '#طلبية الشراء',
    'current_orders.price_unit': 'السعر',
    'current_orders.qty': 'الكمية',
    'current_orders.total_amount': 'الإجمالي',
    'current_orders.delivery_date': 'التسليم',
    'current_orders.confirmation_in': 'القبول ب',
    'current_orders.order_status': 'حالة الطلبية',
    'current_orders.no_orders_available': 'لا يوجد طلبيات',
    'current_orders.current_orders': 'الطلبيات الحالية',
    'current_orders.purchase_date': 'الشراء',
    'current_orders.sort_by': 'صنف حسب',
    'current_orders.success': 'نجح',
    'current_orders.fail': 'فشل',
    'change_password.change_password': 'تغيير كلمة السر',
    'change_password.current_password': 'كلمة السر الحالية',
    'change_password.new_password': 'كلمة السر الجديدة',
    'change_password.confirm_new_password': 'تأكيد كلمة السر الجديدة',
    'article_page.more_from': 'المزيد من ',
    'balance_popover.balance_is_not_available': 'معلومات الرصيد غير متوفرة',
    'balance_popover.order_total': 'اجمالي الطلبيات',
    'balance_popover.available_credit': 'الرصيد المتوفر',
    'balance_popover.your_total_credit_limit': 'إجمالي حد الشراء',
    'survey_popup.complete_survey_note':
      'يرجى قضاء بضع دقائق لإكمال الاستبيان التالي. شكرا لك.',
    'survey_popup.cancel': 'إلغاء',
    'survey_popup.survey': 'استبيان ',
    'blocked_user_popover.my_invoices': 'فواتيري',
    'blocked_user_popover.your_timely_payment_note':
      'لقد تم تقدير تاريخ الدفع الخاص بك في الوقت المناسب للغاية ؛ ومع ذلك ، فقد فات موعد استحقاق بعض الفواتير على حسابك. يرجى تسوية الفواتير المتأخرة الآن لمواصلة الاستمتاع بتجربة التسوق في Floranow.',
    'blocked_user_popover.user_exceeded_purchase_limit_message':
      'You have consumed 90% of your credit limit. Kindly settle your invoices now to continue enjoying the floranow shopping experience uninterrupted',
    'toast_messages.added_to_cart': 'اضيفت الى السلة',
    'toast_messages.po_error_message': 'حدثت مشكلة في إدخال رقم طلب الشراء',
    'toast_messages.you_must_be_logged_in':
      'يجب تسجيل الدخول للإضافة إلى سلة التسوق.',
    'forget_password.title': 'نسيت كلمة المرور',
    'forget_password.enter_your_email':
      'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
    'forget_password.submit': 'إرسال',
    'forget_password.back_to_login': 'صفحة تسجيل الدخول',
    'values.china': 'الصين',
    'values.blue': 'ازرق',
    'values.white': 'ابيض',
    'master_account_popover.you_didnt_add_to_any_of_your_carts':
      'لم تضف إلى أي من عربات التسوق الخاصة بك بعد.',
    'master_account_popover.click_on_subaccount_name_to_access_its_cart':
      'انقر اسم الحساب الفرعي للوصول إلى عربة التسوق الخاصة به.',
    'master_account_popover.list_of_your_subbbaccount_carts':
      'قائمة عربات حسابك الفرعي',
    'master_account_popover.total': 'الاجمالي',
    'master_account_popover.checkout_all_carts': 'تحقق من جميع عربات',
    'master_account_popover.items': 'العناصر',
    'master_account_popover.sub_total': 'المجموع الفرعي',
    'master_account_add_to_cart_modal.total_price': 'اجمالي السعر',
    'master_account_add_to_cart_modal.you_added_for': 'أضفت لـ',
    'master_account_add_to_cart_modal.items': 'العناصر',
    'master_account_add_to_cart_modal.add_to_subaccount_carts':
      'إضافة إلى عربات الحساب الفرعي',
    'master_account_add_to_cart_modal.in_stock': 'في المخزن',
    'master_account_add_to_cart_modal.subaccounts': 'حسابات فرعية',
    'master_account_add_to_cart_modal.close': 'اغلاق',
    'master_account_add_to_cart_modal.search_subaccount_name_or_id':
      'البحث عن اسم الحساب الفرعي أو المعرف',
    'master_account_carts_list.fix': ' تعديل',
    'master_account_carts_list.account_name_and_id': 'اسم الحساب والمعرف',
    'master_account_carts_list.number_of_items': 'عدد العناصر',
    'master_account_carts_list.total_quantity': 'العدد الاجمالي ',
    'master_account_carts_list.sub_total': 'اجمالي الفرعي',
    'master_account_carts_list.carts': 'عربات',
    'master_account_carts_list.items': 'عناصر',
    'master_account_carts_list.total_amount': 'اجمالي',
    'specs.age': 'العمر',
    'specs.number_of_buds': 'عدد التيجان',
    'specs.colour': 'اللون',
    'specs.length': 'الطول',
    'specs.country_of_origin_': 'بلد المنشأ',
    'taxons.commercial_accessories': 'مستلزمات',
    'taxons.commercial_greeneries': 'نباتات خضراء',
    'taxons.commercial_roses': 'ورود',
    'taxons.commercial_plants': 'النباتات',
    'taxons.commercial_others': 'الآخرين',
    'taxons.commercial_orchids': 'زهرة الأوركيد',
    'taxons.commercial_roses_spray-rose': 'Spray Rose',
    'taxons.commercial_flowers_acacia': 'اكاسيا',
    'taxons.commercial_flowers': 'الزهور',
    'general.loading': 'جاري التحميل',
    'general.rows_per_page': 'عدد الصفوف في كل صفحة:',
    'general.pagination_of': 'من',
    'general.pagination_page': 'صفحة',
    'general.pagination_next': 'التالي ←',
    'general.pagination_previous': '→ السابق',
    'general.pagination_next_icon': '←',
    'general.pagination_previous_icon': '→',
    'price.Savings': 'وفر',
    'announcement.agree': 'أوافق',
    'announcement.close': 'أغلق',
    'navigation.home': 'الرئيسية',
    'blocked_user_popover.pay_now': 'ادفع الآن',
    'blocked_user_note.user_exceeded_purchase_limit_message':
      'لقد تجاوزت حد الشراء الخاص بك',
    'filter_modal.button': 'فلتر',
    'currency.AED': 'د.إ',
    'currency.SAR': 'ر.س',
    'currency.JOD': 'د.أ',
    'currency.KWD': 'د.ك',
    'currency.QR': 'ر.ق',
    'appSwitcher.download_our_app': 'للحصول على أفضل تجربة قم بتنزيل تطبيقنا',
    'appSwitcher.continueInWebsite': 'المتابعة في المتصفح',
    'maintenance.days': 'الأيام',
    'maintenance.minutes': 'الدقائق',
    'maintenance.hours': 'الساعات',
    'maintenance.seconds': 'الثواني',
    'app_banner.name': 'FLORANOW',
    'app_banner.publisher': 'سوق الأزهار عبر الإنترنت',
    'app_banner.info_text': 'مجاني - في متجر التطبيقات',
    'app_banner.link_button_text': 'عرض',
  },
  en: {
    'login.username': 'Username',
    'login.password': 'Password',
    'login.login': 'Login',
    'login.register': 'Register',
    'login.forgot_password': 'Forgot Password',
    'login.remember_me': 'Remember me',
    'login.contact_us': 'Contact Us',
    'login.name': 'Name',
    'login.confirm_password': 'Confirm Password',
    'login.email': 'Email',
    'login.phone_number': 'Phone Number',
    'login.business_type': 'Business Type',
    'login.company_name': 'Company Name',
    'login.country': 'Country',
    'login.back_to_login': 'Back to login',
    'login.type_password_again': 'Type your password again',
    'login.language': 'Language',
    'login.new_customer': 'New customer ?',
    'login.flower_shops': 'Flower Shops',
    'login.weddings_and_events': 'Weddings and Events',
    'login.hotels': 'Hotels',
    'login.supermarket': 'Supermarket',
    'login.fully_serviced_client': 'Fully Serviced Client',
    'login.your_full_name': 'Your full name',
    'login.your_password': 'Your password',
    'general.enter': 'ِEnter',
    'footer.about_us': 'About Us',
    'footer.terms_and_conditions': 'Terms & conditions',
    'footer.privacy_policy': 'Privacy Policy',
    'footer.always_here_to_help': "We're Always here To Help",
    'footer.reach_out_to_us':
      'Reach out to us through any of these support channels',
    'footer.email': 'Email',
    'footer.phone_number': 'Phone Number',
    'footer.whatsapp': 'Whatsapp',
    'footer.our_story': 'Our story',
    'footer.our_vision': 'Our vision',
    'footer.our_mission': 'Our mission',
    'footer.updates': 'Updates',
    'footer.news': 'News',
    'footer.blogs': 'Blogs',
    'footer.tips_and_tricks': 'Tips & Tricks',
    'footer.contact_us': 'Contact Us',
    'footer.send_us_a_message': 'Send us a message',
    'footer.stay_up_to_date':
      'Stay up to date with our latest offers, tips, news & events',
    'footer.subscribe': 'Subscribe',
    'footer.write_your_email': 'Write your email',
    'header.login': 'Login',
    'header.register': 'Register',
    'header.account_settings': 'Account Settings',
    'header.balance': 'Balance',
    'header.cart': 'Cart',
    'header.search_input_placeholder':
      'Search, please type in what you are looking for',
    'header.continue_shopping': 'Continue Shopping',
    'header.fetching_items': 'Fetching Items',
    'listing.results_for': 'Results for',
    'listing.total_results': 'Total Results',
    'listing.sort_by': 'Sort by',
    'listing.reset': 'Reset',
    'listing.price': 'Price',
    'listing.colour': 'Colour',
    'listing.name': 'Name',
    'listing.share_result': 'Share result',
    'listing.ascending': 'Ascending',
    'listing.descending': 'Descending',
    'listing.order_by': 'Order by',
    'listing.empty_page': 'Offers will be available soon.',
    'side_filter.delivery_date': 'Delivery Date',
    'side_filter.search': 'Search',
    'side_filter.categories': 'Categories',
    'side_filter.suppliers': 'Suppliers',
    'side_filter.origin_suppliers': 'Sellers',
    'side_filter.colours': 'Colours',
    'side_filter.length_range': 'Length Range',
    'side_filter.price_range': 'Price Range',
    'side_filter.origin': 'Origin',
    'side_filter.search_within_products': 'Search within products',
    'side_filter.colour': 'Colour',
    'side_filter.apply': 'Apply',
    'side_filter.to': 'To',
    'listing_top_filter.remove_filter': 'Remove Filter',
    'listing_top_filter.delivery_date': 'Delivery Date',
    'listing_top_filter.product_name': 'Product Name',
    'listing_top_filter.colour': 'Colour',
    'listing_top_filter.max_length_range': 'Max Length Range',
    'listing_top_filter.supplier': 'Supplier',
    'listing_top_filter.origin_supplier': 'Seller',
    'listing_top_filter.category': 'Category',
    'listing_top_filter.min_length_range': 'Min Length Range',
    'listing_top_filter.origin': 'Origin',
    'listing_top_filter.price_start': 'Price Start',
    'listing_top_filter.price_end': 'Price End',
    'product_item.grower': 'grower',
    'product_item.min_order_unit': 'Min. Order Unit',
    'product_item.supplier': 'Supplier',
    'product_item.seller': 'Seller',
    'product_item.origin_supplier': 'Supplier',
    'product_item.earliest_delivery_date': 'Earliest Delivery Date',
    'product_item.total_price': 'Total price',
    'product_item.add_to_cart': 'Add to cart',
    'product_item.in_stock': 'In Stock',
    'product_item.see_all': 'See All',
    'product_item.prev_offer': 'Previous Offer',
    'product_item.next_offer': 'Next Offer',
    'product_item.qty_in_stock': 'Quantity In Stock',
    'product_item.min_order_qty': 'Minimum Order Quantity',
    'product_item.payment_terms': 'Payment Terms',
    'account_popover.login': 'Login',
    'account_popover.register': 'Register',
    'account_popover.my_account': 'My Account',
    'account_popover.my_profile': 'My Profile',
    'account_popover.logout': 'Log Out',
    'account_popover.orders': 'Orders',
    'account_popover.my_orders': 'My Orders',
    'account_popover.my_current_orders': 'My Current Orders',
    'account_popover.my_invoices': 'My Invoices',
    'account_popover.finance': 'Finance',
    'profile_sidebar.invoices': 'Invoices',
    'profile_sidebar.orders': 'Orders',
    'account_popover.standing_orders': 'Standing Orders',
    'profile_sidebar.finance': 'FINANCE',
    'profile_sidebar.current_orders': 'Current Orders',
    'profile_sidebar.account_info_and_security': 'Account Info & Security',
    'profile_sidebar.my_profile': 'My profile',
    'profile_sidebar.change_password': 'Change password',
    'profile_sidebar.standing_orders': 'Standing Orders',
    'my_profile.general_info': 'General Info',
    'my_profile.name': 'Name',
    'my_profile.email': 'Email',
    'my_profile.phone_number': 'Phone Number',
    'my_profile.company_name': 'Company Name',
    'my_profile.country': 'Country',
    'my_profile.current_password': 'Current Password',
    'my_profile.new_password': 'New Password',
    'my_profile.confirm_new_password': 'Confirm New Password',
    'my_profile.save_changes': 'Save changes',
    'my_profile.need_reset_info':
      'You need to reset your information before proceeding',
    'my_profile.in_order_to_see_subaccount':
      '* In order to see your sub account information please login from the sub account.',
    'my_profile.account_id': 'Account ID',
    'my_profile.no_sub_accounts_available': 'No sub accounts available',
    'cart.payment_terms': 'Payment Terms',
    'cart.remove_all_unavailable_items': 'Remove all',
    'cart.unavailable_items': 'Unavailable Items',
    'cart.available_items': 'Available Items',
    'cart.your_shopping_cart': 'Your Shopping Cart',
    'cart.review_cart_items': 'Review Cart Items',
    'cart.confirm_delivery_date': 'Confirm Delivery Date',
    'cart.review_order_total': 'Review Order Total',
    'cart.track_order': 'Track Order',
    'cart.delivery_date': 'Delivery Date',
    'cart.review_cart_items_smaller': 'Cart Items',
    'cart.confirm_delivery_date_smaller': 'Delivery Date',
    'cart.review_order_total_smaller': 'Checkout',
    'cart.track_order_smaller': 'Track Order',
    'cart.delivery_date_smaller': 'Delivery',
    'cart.delivery': 'Delivery',
    'cart.doc_fees': 'Document Fees',
    'cart.doc_fees': 'Document Fees',
    'cart.doc_fees_details': 'Shipment Document Fees',
    'cart.for_orders_from': 'For orders from ',
    'cart.with_more_than': '  with more than  ',
    'cart.includes_more_than': ' stems, document fees are free; otherwise, a ',
    'cart.will_be_applied': ' fee applies.',
    'cart.no_doc_fees_available': 'No doc fees available',
    'cart.delivery_windows': 'Delivery Windows',
    'cart.unavailable': 'Unavailable',
    'cart.date': 'Date',
    'cart.delivery_time': 'Time',
    'cart.order_total_amount': 'Order Total Amount',
    'cart.delivery_charges': 'Delivery Charges',
    'cart.unavailable_delivery':
      'Unavailable Delivery, You Can Proceed With Checkout Order',
    'cart.time_slots_hint': 'Time slots available only for express',
    'cart.self_pickup_hint':
      'Your order will be set for self-pickup at our store !',
    'cart.charges': 'Charges',
    'cart.total_delivery_charges': 'Total Delivery Charges',
    'cart.total': 'Total',
    'cart.no_delivery_charges_for_orders':
      'No Delivery Charges For Orders Over',
    'cart.add_more_orders_to_get_free_delivery':
      'Add More Orders To Get Free Delivery',
    'cart.hint': 'Hint',
    'cart.hint_message':
      'Your delivery charge is already calculated from previous cart',
    'cart.time_slots': 'Times',
    'cart.sub_total_amount': 'Sub Total Amount',
    'cart.vat_rate': 'VAT total',
    'cart.confirmed_selected_orders': 'Confirm Selected Orders',
    'cart.confirm_order': 'Confirm Order',
    'cart.continue_shopping': 'Continue Shopping',
    'cart.group_by': 'Group by',
    'cart.cart_items': 'Cart Items',
    'cart.item_description': 'Item Description',
    'cart.colour': 'Colour',
    'cart.price_unit': 'Price/Unit',
    'cart.qty': 'Qty.',
    'cart.category': 'Category',
    'cart.supplier': 'Supplier',
    'cart.order_total': 'Order Total',
    'cart.show_orders_placed_in': 'Show orders placed in',
    'cart.last_three_days': 'Last 3 Days',
    'cart.last_three_weeks': 'Last 3 weeks',
    'cart.last_thirty_days': 'Last 30 days',
    'cart.last_three_months': 'Last 3 months ',
    'cart.last_six_months': 'Last 6 months',
    'cart.date_range': 'Date range',
    'cart.your_shopping_cart_is_empty': 'Your Shopping Cart is Empty.',
    'cart.dont_miss_todays_offer':
      'Don’t miss today’s offers and limited deals!',
    'cart.if_you_are_already_logged_in':
      ' If you already have an account, log in to see your cart.',
    'cart.login': 'Login',
    'cart.continue': 'Continue',
    'cart.thank_you_for_shopping': 'Thank you for shopping with Floranow!',
    'cart.check_current_orders': 'Check Current Orders',
    'cart.list_my_orders': 'List My Orders',
    'cart.order_summary': 'Order Summary ',
    'cart.delete': 'Delete',
    'cart.price_type': 'Price Type',
    'cart.cif': 'CIF',
    'cart.fob': 'FOB',
    'cart.additional_cost': 'Additional Cost',
    'cart.are_you_sure_you_want_to_delete':
      'Are you sure you want to delete the selected items?',
    'cart.cancel': 'Cancel',
    'cart.cart_icon': 'cart-icon',
    'cart.back_to_order_list': 'Back to order list',
    'cart.checkout_order': 'Checkout Order',
    'cart.po_number': 'PO Number',
    'cart.enter_optional_po_number': 'Enter optional PO number',
    'cart.po_alphanumeric_validation_error': 'Alphanumeric Only!',
    'invoices.invoices': 'Invoices',
    'invoices.filter': 'FILTER(S)',
    'invoices.apply_filter': 'Apply Filters',
    'invoices.all_invoices': 'All Invoices',
    'invoices.closed': 'Closed',
    'invoices.pending': 'Pending',
    'invoices.last_three_days': 'Last 3 days',
    'invoices.last_three_weeks': 'Last 3 weeks',
    'invoices.last_thirty_days': 'Last 30 days',
    'invoices.last_three_months': 'Last 3 months ',
    'invoices.last_six_months': 'Last 6 months',
    'invoices.date_range': 'Date range',
    'invoices.pay_selected': 'Pay Selected',
    'invoices.invoice_number': 'Invoice#',
    'invoices.order_number': 'Order#',
    'invoices.status': 'Status',
    'invoices.issue_date': 'Issue',
    'invoices.invoice_total': 'Total',
    'invoices.paid_amount': 'Paid',
    'invoices.no_invoices_available': 'No Invoices Available',
    'invoices.show_orders_placed_in': 'Show orders placed in',
    'invoices.paid': 'Paid',
    'invoices.not_paid': 'Not Paid',
    'invoices.print': 'Print',
    'invoices.pay': 'Pay',
    'invoices.description': 'Description',
    'invoices.amount': 'Amount',
    'invoices.total_amount': 'Total Amount',
    'invoices.date': 'Date',
    'invoices.back': 'Back',
    'invoices.placed_in': 'Placed In',
    'invoices.invoice': 'Invoice',
    'invoices.credit_note_amount': 'Credit Note Amount',
    'invoices.type': 'Type',
    'invoices.showing': 'Showing',
    'invoices.page': 'page',
    'invoices.list': 'List',
    'invoices.list_all': 'All',
    'invoices.list_credit_note': 'Credit Note',
    'invoices.list_invoices': 'list_invoices',
    'invoices.actions': 'Actions',
    'orders.orders': 'Orders',
    'orders.no_orders_available': 'No orders available for the current date',
    'orders.image': 'Image',
    'orders.name': 'Name',
    'orders.price': 'Price',
    'orders.confirmed_amount': 'Confirm Amount',
    'orders.confirmed': 'Confirm',
    'orders.total_amount': 'Total Amount',
    'orders.total': 'Total',
    'orders.creation_date': 'Creation Date',
    'orders.creation': 'Creation',
    'orders.estimated_delivery_date': 'Estimated Delivery Date',
    'orders.delivery': 'Delivery',
    'orders.order_number': 'Order Number',
    'orders.number': 'Number',
    'orders.order_type': 'Order Type',
    'orders.type': 'Type',
    'current_orders.reference': 'Reference',
    'standing_orders.standing_orders': 'Standing Orders',
    'standing_orders.order_requests': 'Orders',
    'standing_orders.supplier': 'Supplier',
    'standing_orders.quantity': 'Quantity',
    'standing_orders.unit_price': 'U. Price',
    'standing_orders.currency': 'Currency',
    'standing_orders.approved_quantity': 'Approved Qty.',
    'standing_orders.confirmed': 'Supplier Confirmation',
    'standing_orders.image': 'Image',
    'standing_orders.name': 'Name',
    'standing_orders.reference': 'Ref',
    'standing_orders.delivery_day': 'Day',
    'standing_orders.delivery_date': 'Delivery Date',
    'standing_orders.requested_qty': 'Requested Qty.',
    'standing_orders.confirmed_qty': 'Confirmed Qty.',
    'standing_orders.status': 'Status',
    'standing_orders.orders': 'Orders',
    'standing_orders.no_orders_available': 'No Standing orders available',
    'standing_orders.no_order_request_available': 'No orders available',
    'standing_orders.price': 'Price',
    'current_orders.item_description': 'Description',
    'current_orders.supplier': 'Supplier',
    'current_orders.order_type': 'Type',
    'current_orders.colour': 'Colour',
    'current_orders.po_number': 'PO#',
    'current_orders.price_unit': 'Price',
    'current_orders.qty': 'Qty.',
    'current_orders.total_amount': 'Total',
    'current_orders.delivery_date': 'Delivery',
    'current_orders.confirmation_in': ' Confirmation In',
    'current_orders.order_status': 'Status',
    'current_orders.no_orders_available': 'No orders available',
    'current_orders.current_orders': 'Current Orders',
    'current_orders.purchase_date': 'Purchase',
    'current_orders.sort_by': 'Sort by',
    'current_orders.success': 'Success',
    'current_orders.fail': 'Failed',
    'change_password.change_password': 'Change Password',
    'change_password.current_password': 'Current Password',
    'change_password.new_password': 'New Password',
    'change_password.confirm_new_password': 'Confirm New Password',
    'article_page.more_from': 'More from',
    'balance_popover.balance_is_not_available': 'Balance is not available',
    'balance_popover.order_total': 'Order Total',
    'balance_popover.available_credit': 'Available Credit',
    'balance_popover.your_total_credit_limit': 'Your Total Purchase Limit',
    'survey_popup.complete_survey_note':
      'Please take a few minutes to complete the following survey. Thank you.',
    'survey_popup.cancel': 'Cancel',
    'survey_popup.survey': 'Survey',
    'blocked_user_popover.my_invoices': 'My Invoices',
    'blocked_user_popover.your_timely_payment_note':
      'Your timely payment history has been very much appreciated; however, some invoices on your account are now past due. Kindly settle the overdue invoices now to continue enjoying the Floranow shopping experience.',
    'blocked_user_popover.user_exceeded_purchase_limit_message':
      'You have consumed 90% of your credit limit. Kindly settle your invoices now to continue enjoying the Floranow shopping experience uninterrupted.',
    'toast_messages.added_to_cart': 'Added to cart',
    'toast_messages.po_error_message':
      'An issue has happened with entering the PO number',
    'toast_messages.you_must_be_logged_in':
      'You must be logged in to add to cart.',
    'forget_password.title': 'Forgot Password',
    'forget_password.enter_your_email':
      'Enter your email to reset your password',
    'forget_password.submit': 'Submit',
    'forget_password.back_to_login': 'Back to login',
    'values.china': 'China',
    'values.blue': 'blue',
    'values.white': 'white',
    'master_account_popover.you_didnt_add_to_any_of_your_carts':
      "You didn't add to any of your carts yet.",
    'master_account_popover.click_on_subaccount_name_to_access_its_cart':
      'Click on sub account name to access its cart.',
    'master_account_popover.list_of_your_subbbaccount_carts':
      'List of Your Sub account Carts',
    'master_account_popover.total': 'Total',
    'master_account_popover.checkout_all_carts': 'Checkout All Carts',
    'master_account_popover.items': 'Items',
    'master_account_popover.sub_total': 'Sub-total',
    'master_account_add_to_cart_modal.total_price': 'Total Price',
    'master_account_add_to_cart_modal.you_added_for': 'You added for',
    'master_account_add_to_cart_modal.items': 'items',
    'master_account_add_to_cart_modal.add_to_subaccount_carts':
      'Add To Subaccount Carts',
    'master_account_add_to_cart_modal.in_stock': 'In Stock',
    'master_account_add_to_cart_modal.subaccounts': 'sub accounts',
    'master_account_add_to_cart_modal.close': 'Close',
    'master_account_add_to_cart_modal.search_subaccount_name_or_id':
      'Search sub account name or ID',
    'master_account_carts_list.fix': 'Fix',
    'master_account_carts_list.account_name_and_id': 'Account Name & ID',
    'master_account_carts_list.number_of_items': '# of Items',
    'master_account_carts_list.total_quantity': 'Total Quantity',
    'master_account_carts_list.sub_total': 'Sub-Total',
    'master_account_carts_list.carts': 'Carts',
    'master_account_carts_list.items': 'Items',
    'master_account_carts_list.total_amount': 'Total Amount',
    'general.loading': 'Loading',
    'general.rows_per_page': 'Rows per page:',
    'general.pagination_of': 'of',
    'general.pagination_page': 'Page',
    'general.pagination_next': 'Next →',
    'general.pagination_previous': '← Previous',
    'general.pagination_next_icon': '→',
    'general.pagination_previous_icon': '←',
    'price.Savings': ' Savings',
    'announcement.agree': 'Agree',
    'announcement.close': 'Close',
    'navigation.home': 'Home',
    'blocked_user_popover.pay_now': 'Pay Now',
    'blocked_user_note.user_exceeded_purchase_limit_message':
      'You have exceeded your purchase limit',
    'filter_modal.button': 'Filter',
    'currency.AED': 'AED',
    'currency.SAR': 'SAR',
    'currency.JOD': 'JOD',
    'currency.KWD': 'KWD',
    'currency.QR': 'QR',
    'appSwitcher.download_our_app': 'For the best experience download our app',
    'appSwitcher.continueInWebsite': 'Continue in browser',
    'maintenance.days': 'Days',
    'maintenance.minutes': 'Minutes',
    'maintenance.hours': 'Hours',
    'maintenance.seconds': 'Seconds',
    'app_banner.name': 'FLORANOW',
    'app_banner.publisher': 'The online floral marketplace',
    'app_banner.info_text': 'Free - On the App Store',
    'app_banner.link_button_text': 'VIEW',
  },
};
