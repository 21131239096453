import { userIsMaster, isUserLogged } from '@utils/helpers/userHelpers';
import { getCart as getCartService, createCart as createCartService, setPriceType, getLineItems } from '@app/services/cart';
import keycloak from './keycloak';
export const createCart = async () => {
    try {
        const response = await createCartService();
        const lineItems = await getLineItems();
        localStorage.setItem('lineItems', JSON.stringify(lineItems));
        if (response.status === 201) {
            let cart = '';
            if (await userIsMaster()) {
                cart = JSON.stringify(response.data);
            }
            else {
                cart = JSON.stringify(response.data.data);
            }
            localStorage.setItem('cart', cart);
        }
    }
    catch (error) { }
};
export const getCart = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let cart = '';
            if (isUserLogged()) {
                const response = await getCartService();
                const lineItems = await getLineItems();
                localStorage.setItem('lineItems', JSON.stringify(lineItems));
                if (response.status === 503) {
                    return;
                }
                if (await userIsMaster()) {
                    cart = JSON.stringify(response.data);
                }
                else {
                    cart = JSON.stringify(response.data.data);
                }
                resolve(response.data);
            }
            localStorage.setItem('cart', cart);
        }
        catch (error) {
            // @TODO handle error
            reject(null);
        }
    });
};
export const getAllCarts = () => {
    if (!localStorage.getItem('cart')) {
        return [];
    }
    return JSON.parse(localStorage.getItem('cart'));
};
export const handleCartItemChange = async () => {
    if (typeof localStorage === 'undefined') {
        return;
    }
    if (keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated) {
        try {
            const cartResponse = await getCart();
            if (Array.isArray(cartResponse)) {
                let sum = 0;
                cartResponse.forEach(item => {
                    sum = sum + item.data.relationships.line_items.data.length;
                });
                return sum;
            }
            else {
                return cartResponse.data.relationships.line_items.data.length;
            }
        }
        catch (error) {
            return 0;
        }
    }
};
export const handleStoredCartCount = () => {
    var _storedCart$relations, _storedCart$relations2, _storedCart$relations3;
    if (!localStorage.getItem('cart')) {
        return null;
    }
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (Array.isArray(storedCart)) {
        let sum = 0;
        storedCart.forEach(item => {
            var _item$data, _item$data$relationsh, _item$data$relationsh2, _item$data$relationsh3;
            sum = sum + ((_item$data = item.data) === null || _item$data === void 0 ? void 0 : (_item$data$relationsh = _item$data.relationships) === null || _item$data$relationsh === void 0 ? void 0 : (_item$data$relationsh2 = _item$data$relationsh.line_items) === null || _item$data$relationsh2 === void 0 ? void 0 : (_item$data$relationsh3 = _item$data$relationsh2.data) === null || _item$data$relationsh3 === void 0 ? void 0 : _item$data$relationsh3.length);
        });
        return sum;
    }
    return storedCart === null || storedCart === void 0 ? void 0 : (_storedCart$relations = storedCart.relationships) === null || _storedCart$relations === void 0 ? void 0 : (_storedCart$relations2 = _storedCart$relations.line_items) === null || _storedCart$relations2 === void 0 ? void 0 : (_storedCart$relations3 = _storedCart$relations2.data) === null || _storedCart$relations3 === void 0 ? void 0 : _storedCart$relations3.length;
};
export const switchPriceType = priceType => {
    return new Promise(async (resolve, reject) => {
        try {
            if (isUserLogged()) {
                const response = await setPriceType({
                    price_type: priceType
                });
                if (response.status === 503) {
                    return;
                }
                resolve(response.data);
            }
        }
        catch (error) {
            // @TODO handle error
            reject(null);
        }
    });
};
export const getMainCart = cartResponse => {
    if (Array.isArray(cartResponse)) {
        return cartResponse[0];
    }
    else {
        return cartResponse;
    }
};
