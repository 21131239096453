// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-503-tsx": () => import("./../../../src/pages/503.tsx" /* webpackChunkName: "component---src-pages-503-tsx" */),
  "component---src-pages-account-carts-tsx": () => import("./../../../src/pages/account/carts.tsx" /* webpackChunkName: "component---src-pages-account-carts-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-dummy-tsx": () => import("./../../../src/pages/dummy.tsx" /* webpackChunkName: "component---src-pages-dummy-tsx" */),
  "component---src-pages-dynamic-tsx": () => import("./../../../src/pages/dynamic.tsx" /* webpackChunkName: "component---src-pages-dynamic-tsx" */),
  "component---src-pages-forget-password-tsx": () => import("./../../../src/pages/forget-password.tsx" /* webpackChunkName: "component---src-pages-forget-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-as-tsx": () => import("./../../../src/pages/login-as.tsx" /* webpackChunkName: "component---src-pages-login-as-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

