import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const getCookieValue = key => {
    if (typeof document === "undefined") {
        return null;
    }
    const value = (document.cookie.split('; ').map(i => i.split('=')).filter(i => i[0] == key)[0] || []).slice(1).join('=');
    return value;
};
/**
 * @see https://en.wikipedia.org/wiki/Year_2038_problem
 *
 * @param key
 * @param value
 */
export const setCookieValue = (key, value) => {
    document.cookie = key + "=" + value + "; domain=" + process.env.COOKIE_DOMAIN + "; samesite=strict; expires=Thu, 01 Jan 2038 00:00:00 UTC; path=/";
};
export const clearUserCookies = () => {
    cookies.remove('user', {
        path: '/',
        domain: process.env.COOKIE_DOMAIN
    });
    cookies.remove('return-url', {
        path: '/',
        domain: process.env.COOKIE_DOMAIN
    });
    cookies.remove('is_master', {
        path: '/',
        domain: process.env.COOKIE_DOMAIN
    });
};
